import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";
import Datatable from "../../common/tables/components/Datatable";

  const PV = Loadable({
    loader: () => import("./components/purchase_voucher"),
    loading: Loading
  });  

  const MRNV = Loadable({
    loader: () => import("./components/mrn_voucher"),
    loading: Loading
  });

  const LPV = Loadable({
    loader: () => import("./components/lp_voucher"),
    loading: Loading
  });

  const DNV = Loadable({
    loader: () => import("./components/deliverynote_voucher"),
    loading: Loading
  });

  const ISKTV = Loadable({
    loader: () => import("./components/issuektreq_voucher"),
    loading: Loading
  });
  
  const KTV = Loadable({
    loader: () => import("./components/ktreq_voucher"),
    loading: Loading
  });

  const OBV = Loadable({
    loader: () => import("./components/orderbooking_voucher"),
    loading: Loading
  });
  
  const PIV = Loadable({
    loader: () => import("./components/pi_voucher"),
    loading: Loading
  });

  const TIV = Loadable({
    loader: () => import("./components/ti_voucher"),
    loading: Loading
  });

export const routes = [ 
   {
    path: "/voucher_print/purchase_voucher",
    exact: true,
    component: PV,
    name: "Purchase Order Print"
  },
  {
    path: "/voucher_print/mrn_voucher",
    exact: true,
    component: MRNV,
    name: "MRN FROM PO Print"
  },
  {
    path: "/voucher_print/lp_voucher",
    exact: true,
    component: LPV,
    name: "Local Purchase Print"
  },
  {
    path: "/voucher_print/deliverynote_voucher",
    exact: true,
    component: DNV,
    name: "Local Purchase Print"
  },
  {
    path: "/voucher_print/issuektreq_voucher",
    exact: true,
    component: ISKTV,
    name: "Issue Kitchen Print"
  },
  {
    path: "/voucher_print/ktreq_voucher",
    exact: true,
    component: KTV,
    name: "Kitchen Requisition Print"
  },
  {
    path: "/voucher_print/orderbooking_voucher",
    exact: true,
    component:OBV,
    name: "Order Booking Print"
  },
  {
    path: "/voucher_print/pi_voucher",
    exact: true,
    component:PIV,
    name: "Performa Invoice Print"
  },
  {
    path: "/voucher_print/ti_voucher",
    exact: true,
    component:TIV,
    name: "Tax Invoice Print"
  }

];
