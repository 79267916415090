import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import ResetWidgets from "./ResetWidgets";
import SmallBreadcrumbs from "./SmallBreadcrumbs";
import { config } from "../../../config/config";

import {isEmpty} from '../../../comfunc';
import axios from 'axios';
const dbservConfig = {
  basePath: config.db_url
};

const tooltip = (
  <Tooltip id="reset-widgets-suggestion">
    <i className="text-warning fa fa-warning" /> Warning! This will reset all
    your widget settings.
  </Tooltip>
);
const userdata = JSON.parse(localStorage.getItem('user'));
const user  =  userdata == null ? "" : userdata

export default class Ribbon extends React.Component {
  constructor() {
    super();
    this.state = {      
      username: ''   
};
  }
  componentWillMount() {

    if(user.id == 0)
    {
    this.setState({username: "SUPERADMIN"})
  }
  else
  {
    const ow = JSON.parse(localStorage.getItem('ow'));
  axios.get(dbservConfig.basePath+'/api/custom/selectall', { params: { t: 'sspl_usersow',cols:'*', whrc: "id = '"+ow+"'"} }) 
  .then( (user) => { 
    var owid = user.data[0].n_owid   
    var nid =  user.data[0].n_id 
    //console.log("genearated id loginfo page",nid);
    axios.get(dbservConfig.basePath+'/api/custom/selectall', { params: { t: 'view_sspl_userdetails', whrc: "id = '"+nid+"'"} }) 
    .then( (response) => { 
   // var utype =  response.data[0].usertype     
   this.setState({username: response.data[0].username});           
   }).catch( (error) => { console.log(error); }); 
  }).catch( (error) => { console.log(error); });
}
  }


  render() {
    return (
      <div id="ribbon" className="fixed-ribbon">
        <span className="ribbon-button-alignment">
          <OverlayTrigger placement="bottom" overlay={tooltip}>
            <ResetWidgets />     
          </OverlayTrigger>
         
        </span>
        <SmallBreadcrumbs />
        <marquee style={{ color:"#fefbfe", width:"80%" }}
          duration={3000}
          marqueeOnStart
          loop
          marqueeDelay={1000}
          marqueeResetDelay={1000}>Sparktech Systems Pvt Ltd (SSPL) provides Spark Base Kitchen Software Solution for Central Kitchen Monitoring for single or multiple outlets.</marquee>
           <span style={{ color:"#fefbfe",float:"right",padding:"12px",background:"black"}}>{this.state.username == null ? "" : this.state.username}</span>
      </div>
    );
  }
}
