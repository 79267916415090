import React from "react";
import ToggleShortcut from "./ToggleShortcut";
import axios from 'axios';
import { connect } from "react-redux";
import {config} from '../../../config/config';
const dbservConfig = {
  basePath: config.db_url
}; 

const userdata = JSON.parse(localStorage.getItem('user'));
const user  =  userdata == null ? "" : userdata

class LoginInfo extends React.Component {
  constructor() {
    super();
    this.state = {      
      n_id: user.id,
      n_userid : user.c_cid,
      n_utype : user.n_utype,nutype:'' 
};
  }
  componentWillMount() {
    
  //  console.log("ow value in loginpage",ow)
    if(user.id == 0)
    {
    this.setState({usertype: "SUPERADMIN"})
  }
  else
  {
    const ow = JSON.parse(localStorage.getItem('ow'));
  axios.get(dbservConfig.basePath+'/api/custom/selectall', { params: { t: 'sspl_usersow',cols:'*', whrc: "id = '"+ow+"'"} }) 
  .then( (user) => { 
    var owid = user.data[0].n_owid   
    var nid =  user.data[0].n_id 
    //console.log("genearated id loginfo page",nid);
    axios.get(dbservConfig.basePath+'/api/custom/selectall', { params: { t: 'view_sspl_userdetails', whrc: "id = '"+nid+"'"} }) 
    .then( (response) => { 
   // var utype =  response.data[0].usertype     
   this.setState({usertype: response.data[0].usertype});           
   }).catch( (error) => { console.log(error); }); 
  }).catch( (error) => { console.log(error); });
}
  }
 
  render() {
    return (
      <div className="login-info">
        <span>
          <ToggleShortcut>
            <img src={this.props.picture} alt="me" className="online" />
            <span>{this.state.usertype == null ? "" : this.state.usertype}</span>
            <i className="fa fa-angle-down" />
          </ToggleShortcut>
        </span>
      </div>
    );
  }
}

const mapStateToProps = state => state.user;
export default connect(mapStateToProps)(LoginInfo);
