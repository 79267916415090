import { routes as appViews } from "./views/app-views";
import { routes as auth } from "./views/auth";
import { routes as calendar } from "./views/calendar";
import { routes as dashboards } from "./views/dashboard";
import { routes as eCommerce } from "./views/e-commerce";
import { routes as forms } from "./views/forms";
import { routes as graphs } from "./views/graphs";
import { routes as maps } from "./views/maps";
import { routes as misc } from "./views/misc";
import { routes as outlook } from "./views/outlook";
import { routes as smartadminIntel } from "./views/smartadmin-intel";
import { routes as tables } from "./views/tables";
import { routes as ui } from "./views/ui";
import { routes as widgets } from "./views/widgets";
import { routes as master } from "./views/master";
import { routes as transactions } from "./views/transactions";
import { routes as invoices } from "./views/invoices";
import { routes as production } from "./views/production";
import { routes as voucher_print } from "./views/voucher_print";
import { routes as reports } from "./views/reports";

export const routes = [
  
  ...appViews,
  ...calendar,
  ...dashboards,
  ...eCommerce,
  ...forms,
  ...graphs,
  ...maps,
  ...misc,
  ...outlook,
  ...smartadminIntel,
  ...tables,
  ...ui,
  ...widgets,
  ...master,
  ...transactions,
  ...invoices,
  ...production,
  ...voucher_print,
  ...reports
];

export const authRoutes = [
  ...auth
];
