import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";
import Datatable from "../../common/tables/components/Datatable";

  const Taxinvoice = Loadable({
    loader: () => import("./components/taxinvoice"),
    loading: Loading
  });
  const Performainvoice = Loadable({
    loader: () => import("./components/performainvoice"),
    loading: Loading
  }); 

  const OutletIssue = Loadable({
    loader: () => import("./components/outletissue"),
    loading: Loading
  });

  const OutletIssuereturn = Loadable({
    loader: () => import("./components/outletissuereturn"),
    loading: Loading
  });
  const OutletIssuereturndata = Loadable({
    loader: () => import("./components/outletissuereturndata"),
    loading: Loading
  });
  
  const Taxinvoicer = Loadable({
    loader: () => import("./components/taxinvoicereturn"),
    loading: Loading
  });
  const Taxinvoicerd = Loadable({
    loader: () => import("./components/taxinvoicereturndata"),
    loading: Loading
  });


export const routes = [ 
   {
    path: "/invoices/taxinvoice",
    exact: true,
    component: Taxinvoice,
    name: "Tax Invoice"
  },  
  {
    path: "/invoices/performainvoice",
    exact: true,
    component: Performainvoice,
    name: "Performa Invoice"
  },  
  {
    path: "/invoices/outletissue",
    exact: true,
    component: OutletIssue,
    name: "Outlet Issue"
  },  
  {
    path: "/invoices/outletissuereturn",
    exact: true,
    component: OutletIssuereturn,
    name: "Outlet Issue Return"
  },  
  {
    path: "/invoices/outletissuereturndata",
    exact: true,
    component: OutletIssuereturndata,
    name: "Outlet Issue Return Data"
  },
  {
    path: "/invoices/taxinvoicereturn",
    exact: true,
    component: Taxinvoicer,
    name: "Tax Invoice Return"
  },
  {
    path: "/invoices/taxinvoicereturndata",
    exact: true,
    component: Taxinvoicerd,
    name: "Tax Invoice Return Data"
  }  
];
