import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";
import Datatable from "../../common/tables/components/Datatable";

const ActualProduction = Loadable({
  loader: () => import("./components/actual_production"),
  loading: Loading
});

const KitchenWastage = Loadable({
  loader: () => import("./components/kitchen_wastage"),
  loading: Loading
});

const Productions = Loadable({
  loader: () => import("./components/productions"),
  loading: Loading
});

const StoreClosing = Loadable({
  loader: () => import("./components/sclosing"),
  loading: Loading
});





export const routes = [ 
  {
    path: "/production/actual_production",
    exact: true,
    component: ActualProduction,
    name: "Actual Production"
  },
  {
    path: "/production/kitchen_wastage",
    exact: true,
    component: KitchenWastage,
    name: "Kitchen Wastage"
  },
  {
    path: "/production/productions",
    exact: true,
    component: Productions,
    name: "Productions"
  },
  {
    path: "/production/sclosing",
    exact: true,
    component: StoreClosing,
    name: "Store Adjustment"
  }
 
 
];
