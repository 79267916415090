import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import { config } from "../../../config/config";
import * as NavigationActions from '../NavigationActions'
import axios from 'axios';
const dbservConfig = {
  basePath: config.db_url
};
 
const userdata = JSON.parse(localStorage.getItem('user'));
const user  =  userdata == null ? "" : userdata


class Shortcut extends React.Component {
  constructor() {
    super();
    this.state = {     
      n_id: user.id,
      n_userid : user.c_cid,
      n_utype : user.n_utype, 
      prvlg:[],
      adsettings : 0
    };
  }
  componentDidMount() {    

   
     if(user.id == 0)
      {
      axios.get(dbservConfig.basePath+'/api/custom/selectall',  { params: { t: 'view_sspl_navprevilege', cols: '*', whrc: " n_id = 0 and parentid = 1"} }) 
      .then( (res) => {
      this.setState({prvlg : res.data,adsettings : 1}); })
      .catch( (error) => { console.log(error); });
     }
     else
     {   

     const ow = JSON.parse(localStorage.getItem('ow'));
     // console.log("shortcut else",ow);
     axios.get(dbservConfig.basePath+'/api/custom/selectall', { params: { t: 'sspl_usersow', whrc: "id = '"+ow+"'"} }) 
     .then( (response) => { 
     var n_userid =  response.data[0].n_owid
     var nid =   response.data[0].n_id
    // console.log("shortcut else nid",nid);
     this.setState({n_userid: response.data[0].n_owid}); 
     axios.get(dbservConfig.basePath+'/api/custom/selectall',  { params: { t: 'view_sspl_navprevilege', cols: '*', whrc: " n_id = '"+nid+"' and parentid = 1 and pshow = 1"} }) 
     .then( (res) => {
     this.setState({prvlg : res.data}); })
     .catch( (error) => { console.log(error); });       
     }).catch( (error) => { console.log(error); }); 
    }
   


  }
  onLinkClick = (e)=>{
    this.props.closeShortcut()
  }
  onBackClick = (e)=>{
    e.preventDefault()
    this.props.closeShortcut()
  }
  render() {
    return (
      <Collapse in={this.props.shortcutOpen} mountOnEnter={true} unmountOnExit={true}>
        <div id="shortcut" style={{display: 'block'}}>
          <ul onClick={this.onBackClick}>

          {this.state.prvlg.map((celldata,index) => {
           return  (<li>
              <Link onClick={this.onLinkClick}
                to={celldata.url}
                title="Inbox" 
               // className={celldata.class} selected>
               className= {`selected ${celldata.class}`}>
                <span className="iconbox">
                <i className={celldata.icons}/>
                <span>{celldata.cname}</span>
                </span>
              </Link>
            </li>)
         })}          
           
           <li>
              <Link onClick={this.onLinkClick}
                to="/master/user"
                className="jarvismetro-tile big-cubes selected bg-color-pinkDark">
                <span className="iconbox">
                  <i className="fa fa-user fa-4x" /> <span>My Profile </span>
                </span>
              </Link>
            </li>
            {this.state.adsettings == 1 ? 
            <li>
              <Link onClick={this.onLinkClick}
                to="/master/admin_settings"
                className="jarvismetro-tile big-cubes selected bg-color-blueDark"
              >
                <span className="iconbox">
                  <i className="fa fa-user-plus fa-4x" /> <span>User Previlege </span>
                </span>
              </Link>
            </li>   : ""}

           {/* <li>
              <Link onClick={this.onLinkClick}
                to="/master/citymaster"
                title="Inbox"
                className="jarvismetro-tile big-cubes bg-color-blue"
              >
                <span className="iconbox">
                  <i className="fa fa-globe fa-4x" />
                  <span>
                    City Master                   
                  </span>
                </span>
              </Link>
            </li>
            <li>
              <Link onClick={this.onLinkClick}
                to="/master/generalmaster"
                className="jarvismetro-tile big-cubes bg-color-orangeDark"
              >
                <span className="iconbox">
                  <i className="fa fa-bars fa-4x" /> <span>General Master</span>
                </span>
              </Link>
            </li>
            <li>
              <Link onClick={this.onLinkClick}
                to="/master/unitmaster"
                className="jarvismetro-tile big-cubes bg-color-blueDark">
                <span className="iconbox">
                  <i className="fa  fa-balance-scale fa-4x" /> <span>Unit Master </span>
                </span>
              </Link>
            </li>
            <li>
              <Link onClick={this.onLinkClick}
                to="/master/taxmaster"
                className="jarvismetro-tile big-cubes bg-color-greenLight">
                <span className="iconbox">
                  <i className="fa  fa-inr  fa-4x" /> <span>Tax Master </span>
                </span>
              </Link>
            </li>
            <li>
              <Link onClick={this.onLinkClick}
                to="/master/itemtypemaster"
                className="jarvismetro-tile big-cubes bg-color-green">
                <span className="iconbox">
                  <i className="fa  fa-credit-card fa-4x" /> <span>Item Type Master </span>
                </span>
              </Link>
            </li>
            <li>
              <Link onClick={this.onLinkClick}
                to="/master/subitemcategory"
                className="jarvismetro-tile big-cubes bg-color-yellow">
                <span className="iconbox">
                  <i className="fa  fa-bars fa-4x" /> <span>Sub Item Category</span>
                </span>
              </Link>
            </li>
            <li>
              <Link onClick={this.onLinkClick}
                to="/master/subheading"
                className="jarvismetro-tile big-cubes bg-color-redLight">
                <span className="iconbox">
                  <i className="fa  fa-header fa-4x" /> <span>Sub Heading</span>
                </span>
              </Link>
            </li>
            <li>
              <Link onClick={this.onLinkClick}
                to="/master/admin_settings"
                className="jarvismetro-tile big-cubes selected bg-color-blueDark"
              >
                <span className="iconbox">
                  <i className="fa fa-user-plus fa-4x" /> <span>User Previlege </span>
                </span>
              </Link>
            </li>
            <li>
              <Link onClick={this.onLinkClick}
                to="/master/user"
                className="jarvismetro-tile big-cubes selected bg-color-pinkDark"
              >
                <span className="iconbox">
                  <i className="fa fa-user fa-4x" /> <span>My Profile </span>
                </span>
              </Link>
            </li>
           */}
            </ul>
        </div>
      </Collapse>
    );
  } 
}


export default connect(
  store => store.navigation,
  dispatch => bindActionCreators(NavigationActions, dispatch)
)(Shortcut);


