import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";
import Datatable from "../../common/tables/components/Datatable";

const Customer = Loadable({
  loader: () => import("./components/customer"),
  loading: Loading
});

const Importitems = Loadable({
  loader: () => import("./components/importitems"),
  loading: Loading
});

const Exportitems = Loadable({
  loader: () => import("./components/exportitems"),
  loading: Loading
});

const User = Loadable({
    loader: () => import("./components/user"),
    loading: Loading
  });

  const CreateUser = Loadable({
    loader: () => import("./components/user_"),
    loading: Loading
  });
  
  const Usermaster = Loadable({
    loader: () => import("./components/usermaster"),
    loading: Loading
  });
  
  const Statemaster = Loadable({
    loader: () => import("./components/statemaster"),
    loading: Loading
  });

  const Citymaster = Loadable({
    loader: () => import("./components/citymaster"),
    loading: Loading
  });

  const Unitmaster = Loadable({
    loader: () => import("./components/unitmaster"),
    loading: Loading
  });

  const Productionmaster = Loadable({
    loader: () => import("./components/productionmaster"),
    loading: Loading
  });

  const Outletmaster = Loadable({
    loader: () => import("./components/outletmaster"),
    loading: Loading
  });

  const Warehousemaster = Loadable({
    loader: () => import("./components/warehouemaster"),
    loading: Loading
  });

  const Suppliermaster = Loadable({
    loader: () => import("./components/suppliermaster"),
    loading: Loading
  });

  const Supplierrate = Loadable({
    loader: () => import("./components/supplierrate"),
    loading: Loading
  });

  const Generalmaster = Loadable({
    loader: () => import("./components/generalmaster"),
    loading: Loading
  });

  const Itemmaster = Loadable({
    loader: () => import("./components/itemmaster"),
    loading: Loading
  });

  const Taxmaster = Loadable({
    loader: () => import("./components/taxmaster"),
    loading: Loading
  });

  const Itemtypemaster = Loadable({
    loader: () => import("./components/itemtypemaster"),
    loading: Loading
  });

  const Outletmapping = Loadable({
    loader: () => import("./components/outletmapping"),
    loading: Loading
  });
 
  const Suppliermapping = Loadable({
    loader: () => import("./components/suppliermapping"),
    loading: Loading
  });

  const Subunit = Loadable({
    loader: () => import("./components/subunitmaster"),
    loading: Loading
  });

  const Minorder = Loadable({
    loader: () => import("./components/minorder"),
    loading: Loading
  });

  const Subitemcat = Loadable({
    loader: () => import("./components/subitemcategory"),
    loading: Loading
  });


  const Subheading = Loadable({
    loader: () => import("./components/subheading"),
    loading: Loading
  });

  const Dishmaster = Loadable({
    loader: () => import("./components/dishmaster"),
    loading: Loading
  });

  
  const OpeningStock = Loadable({
    loader: () => import("./components/opening_stock"),
    loading: Loading
  });

  const Receipe = Loadable({
    loader: () => import("./components/receipe"),
    loading: Loading
  });

  const Receipeimport = Loadable({
    loader: () => import("./components/importreceipe"),
    loading: Loading
  });

  const Settings = Loadable({
    loader: () => import("./components/settings"),
    loading: Loading
  });


  const AdminSettings = Loadable({
    loader: () => import("./components/admin_settings"),
    loading: Loading
  });


  const ImportExport = Loadable({
    loader: () => import("./components/import_export"),
    loading: Loading
  });

const Dashboard = Loadable({
  loader: () => import("../dashboard/components/Dashboard"),
  loading: Loading
});

export const routes = [
  {
    path: "/master/import_export",
    exact: true,
    component: ImportExport,
    name: "Create Customer"
  },
  {
    path: "/master/dashboard",
    exact: true,
    component: Dashboard,
    name: "Create Customer"
  },
  {
    path: "/master/customer",
    exact: true,
    component: Customer,
    name: "Create Customer"
  },
  {
    path: "/master/importitems",
    exact: true,
    component: Importitems,
    name: "Import Items"
  },
  {
    path: "/master/exportitems",
    exact: true,
    component: Exportitems,
    name: "Import Items"
  },
  {
    path: "/master/user",
    exact: true,
    component: User,
    name: "Create User"
  },
  {
    path: "/master/createuser",
    exact: true,
    component: CreateUser,
    name: "Create User"
    
  },
  {
    path: "/master/usermaster",
    exact: true,
    component: Usermaster,
    name: "Create User"
  },
  {
    path: "/master/viewusermaster",
    exact: true,
    component: Datatable,
    name: "View User Master"
  },
  {
    path: "/master/statemaster",
    exact: true,
    component: Statemaster,
    name: "State Master"
  },
  {
    path: "/master/citymaster",
    exact: true,
    component: Citymaster,
    name: "City Master"
  },
  {
    path: "/master/unitmaster",
    exact: true,
    component: Unitmaster,
    name: "Unit Master"
  },
  {
    path: "/master/productionmaster",
    exact: true,
    component: Productionmaster,
    name: "Production Master"
  },
  {
    path: "/master/outletmaster",
    exact: true,
    component: Outletmaster,
    name: "Outlet Master"
  },
  {
    path: "/master/warehousemaster",
    exact: true,
    component: Warehousemaster,
    name: "Warehouse Master"
  },
  
  {
    path: "/master/suppliermaster",
    exact: true,
    component: Suppliermaster,
    name: "Supplier Master"
  },
  
  {
    path: "/master/supplierrate",
    exact: true,
    component: Supplierrate,
    name: "Supplier Rate Contract"
  },  
  {
    path: "/master/generalmaster",
    exact: true,
    component: Generalmaster,
    name: "General Master"
  },  
  {
    path: "/master/itemmaster",
    exact: true,
    component: Itemmaster,
    name: "Item Master"
  },  
  {
    path: "/master/taxmaster",
    exact: true,
    component: Taxmaster,
    name: "Tax Master"
  },  
  {
    path: "/master/itemtypemaster",
    exact: true,
    component: Itemtypemaster,
    name: "Item Type Master"
  },  
  {
    path: "/master/outletmapping",
    exact: true,
    component: Outletmapping,
    name: "Outlet Mapping"
  },  
  {
    path: "/master/suppliermapping",
    exact: true,
    component: Suppliermapping,
    name: "Supplier Mapping"
  },  
  {
    path: "/master/subunitmaster",
    exact: true,
    component: Subunit,
    name: "Sub Unit Master"
  },  
  {
    path: "/master/minorder",
    exact: true,
    component: Minorder,
    name: "Min Order Record"
  },  
  {
    path: "/master/subitemcategory",
    exact: true,
    component: Subitemcat,
    name: "Sub Item"
  },  
  {
    path: "/master/subheading",
    exact: true,
    component: Subheading,
    name: "Sub Heading"
  },  
  {
    path: "/master/dishmaster",
    exact: true,
    component: Dishmaster,
    name: "Dish Master"
  },  
  {
    path: "/master/opening_stock",
    exact: true,
    component: OpeningStock,
    name: "Opening Stock Entry"
  }
  ,
  {
    path: "/master/receipe",
    exact: true,
    component: Receipe,
    name: "Receipe"
  },
  {
    path: "/master/importreceipe",
    exact: true,
    component: Receipeimport,
    name: "Import Receipe"
  },
  {
    path: "/master/settings",
    exact: true,
    component: Settings,
    name: "Settings"
  }
  ,
  {
    path: "/master/admin_settings",
    exact: true,
    component: AdminSettings,
    name: "Settings"
  }

];
