import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";
import Datatable from "../../common/tables/components/Datatable";

  const Purchaseorder = Loadable({
    loader: () => import("./components/purchaseorder"),
    loading: Loading
  });

  const Indent = Loadable({
    loader: () => import("./components/indent"),
    loading: Loading
  });

  const MRN = Loadable({
    loader: () => import("./components/mrn"),
    loading: Loading
  });

  const DIRECTMRN = Loadable({
    loader: () => import("./components/directmrn"),
    loading: Loading
  });

  const KTREQ = Loadable({
    loader: () => import("./components/kitchen_requisition"),
    loading: Loading
  });

  const ISKTREQ = Loadable({
    loader: () => import("./components/issue_kitchen_requisition"),
    loading: Loading
  });

  const OrderBooking = Loadable({
    loader: () => import("./components/order_booking"),
    loading: Loading
  });


  const MOQ = Loadable({
    loader: () => import("./components/minimum_order_quantity"),
    loading: Loading
  });
  
  const Directindent = Loadable({
    loader: () => import("./components/directindent"),
    loading: Loading
  });

  const Mrnreturn = Loadable({
    loader: () => import("./components/mrnreturn"),
    loading: Loading
  });

  const Mrnreturndata = Loadable({
    loader: () => import("./components/mrnreturndata"),
    loading: Loading
  });

  const Directmrnreturn = Loadable({
    loader: () => import("./components/directmrnreturn"),
    loading: Loading
  });

  const Dmrnreturndata = Loadable({
    loader: () => import("./components/directmrnreturndata"),
    loading: Loading
  });

  const Ikrr = Loadable({
    loader: () => import("./components/ikr_return"),
    loading: Loading
  });
  const Ikrrd = Loadable({
    loader: () => import("./components/ikrreturndata"),
    loading: Loading
  });
  
export const routes = [ 
  {
    path: "/transactions/purchaseorder",
    exact: true,
    component: Purchaseorder,
    name: "Purchase Order"
  },  
  {
    path: "/transactions/indent",
    exact: true,
    component: Indent,
    name: "Indent"
  },
  {
    path: "/transactions/minimum_order_quantity",
    exact: true,
    component: MOQ,
    name: "Minimum Order Quantity"
  },  
  {
    path: "/transactions/mrn",
    exact: true,
    component: MRN,
    name: "Store or Direct Kitchen MRN"
  },  
  {
    path: "/transactions/directmrn",
    exact: true,
    component: DIRECTMRN,
    name: "Store or Direct Kitchen MRN"
  }, 
  {
    path: "/transactions/directmrnreturn",
    exact: true,
    component: Directmrnreturn,
    name: "Local Purchase Return"
  },  
  {
    path: "/transactions/kitchen_requisition",
    exact: true,
    component: KTREQ,
    name: "Kitchen Requisition"
  }
  ,  
  {
    path: "/transactions/issue_kitchen_requisition",
    exact: true,
    component: ISKTREQ,
    name: "Kitchen Requisition"
  }
  ,  
  {
    path: "/transactions/order_booking",
    exact: true,
    component: OrderBooking,
    name: "Order Booking"
  } 
  ,  
  {
    path: "/transactions/directindent",
    exact: true,
    component: Directindent,
    name: "Direct indent"
  },  
  {
    path: "/transactions/mrnreturn",
    exact: true,
    component: Mrnreturn,
    name: "MRN Return"
  },  
  {
    path: "/transactions/mrnreturndata",
    exact: true,
    component: Mrnreturndata,
    name: "MRN Return Data"
  },  
  {
    path: "/transactions/directmrnreturndata",
    exact: true,
    component: Dmrnreturndata,
    name: "Local Purchase Return Data"
  },  
  {
    path: "/transactions/ikr_return",
    exact: true,
    component: Ikrr,
    name: "Kitchen Requisition Return"
  },  
  {
    path: "/transactions/ikrreturndata",
    exact: true,
    component: Ikrrd,
    name: "Kitchen Requisition Return Data"
  }       
  

];
