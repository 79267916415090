import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";
import Datatable from "../../common/tables/components/Datatable";

  const ClosingBal = Loadable({
    loader: () => import("./components/closingbal"),
    loading: Loading
  });  

  const ReceipeRpt = Loadable({
    loader: () => import("./components/receiperpt"),
    loading: Loading
  }); 

  const ItemLedger = Loadable({
    loader: () => import("./components/itemledger"),
    loading: Loading
  }); 

  const PurchaseRegister = Loadable({
    loader: () => import("./components/purchase_register"),
    loading: Loading
  }); 
  const MrnDetailReport = Loadable({
    loader: () => import("./components/mrn_detail_report"),
    loading: Loading
  }); 

  const PODetailsReport = Loadable({
    loader: () => import("./components/po_detail_report"),
    loading: Loading
  }); 

  const POReport = Loadable({
    loader: () => import("./components/po_report"),
    loading: Loading
  });

  const POPendingReport = Loadable({
    loader: () => import("./components/po_pending_report"),
    loading: Loading
  });


  const StockInHand = Loadable({
    loader: () => import("./components/stockinhand_report"),
    loading: Loading
  });

  const StockInHandFinished = Loadable({
    loader: () => import("./components/stockinhandfinished"),
    loading: Loading
  });



  const StoreLedger = Loadable({
    loader: () => import("./components/storeledger"),
    loading: Loading
  });

  const StoreLedgerFinish = Loadable({
    loader: () => import("./components/storeledgerfinish"),
    loading: Loading
  });



export const routes = [ 
   {
    path: "/reports/closing_balance",
    exact: true,
    component: ClosingBal,
    name: "Closing Balance Report"
  },
  {
    path: "/reports/mrn_report",
    exact: true,
    component: ItemLedger,
    name: "Item Ledger Report"
  },
  
  {
    path: "/reports/purchase_register",
    exact: true,
    component: PurchaseRegister,
    name: "Purchase Register Report"
  },
  {
    path: "/reports/mrn_detail_report",
    exact: true,
    component: MrnDetailReport,
    name: "MRN Detail Report"
  },
  {
    path: "/reports/po_detail_report",
    exact: true,
    component: PODetailsReport,
    name: "PO Detail Report"
  },
  {
    path: "/reports/po_report",
    exact: true,
    component: POReport,
    name: "PO Report"
  },  
  {
    path: "/reports/po_pending_report",
    exact: true,
    component: POPendingReport,
    name: "PO Report"
  },
  {
    path: "/reports/stockinhand_report",
    exact: true,
    component: StockInHand,
    name: "Stock In Hand  Report"
  },
  {
    path: "/reports/stock_in_hand_finish",
    exact: true,
    component: StockInHandFinished,
    name: "Stock In Hand Finished Report"
  },
  {
    path: "/reports/store_ledger",
    exact: true,
    component: StoreLedger,
    name: "Store Ledger Report"
  },
  {
    path: "/reports/store_ledger_finish",
    exact: true,
    component: StoreLedgerFinish,
    name: "Sore Ledger Finish Report"
  },

 

];
