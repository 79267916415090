import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const Dashboard = Loadable({
  loader: () => import("./components/Dashboard"),
  loading: Loading
});

const Dashboard2 = Loadable({
  loader: () => import("./components/Dashboard2"),
  loading: Loading
});



const Social = Loadable({
  loader: () => import("./components/Social"),
  loading: Loading
});

export const routes = [
  {
    path:  "/",
    exact: true,
    name: "Dashboard",
    component: Dashboard
  },
  {
    path:  "/dashboard/analytics",
    exact: true,
    name: "Dashboard",
    component: Dashboard
  },
  {
    path:  "/dashboard/abc",
    exact: true,
    name: "Dashboard",
    component: Dashboard2
  },
  {
    path: "/dashboard/social-wall",
    exact: true,
    name: "Social",
    component: Social
  }
];
