import React from "react";
import {smallBox, /*bigBox,*/ SmartMessageBox} from "../../../common/utils/functions/message";
 
export default class Logout extends React.Component {
  onLogout(e) {
    e.preventDefault();
    var user = JSON.parse(localStorage.getItem('user'));
    SmartMessageBox({
      title: "<i class='fa fa-sign-out txt-color-orangeDark'></i> Logout <span class='txt-color-orangeDark'>"+user.username+"</span> ?",
      content: "Are you sure, you want to logout ?",
      buttons: '[No][Yes]'
    }, function (ButtonPressed) {
      if (ButtonPressed === "Yes") {
        localStorage.removeItem("user");
        localStorage.removeItem("ow");
        localStorage.clear();

        window.location.reload();
        
      }
      if (ButtonPressed === "No") {
        smallBox({
          title: "Happy to see you back.",
          content: "<i class='fa fa-clock-o'></i> <i>You pressed No...</i>",
          color: "#C46A69",
          iconSmall: "fa fa-times fa-2x fadeInRight animated",
          timeout: 4000
        });
      }

    });
  }
  render() {
    return (

          <div id="logout" className="btn-header transparent pull-right">
            
            <span>
              <a
                href="#/login"
                title="Sign Out"
                onClick={this.onLogout}
              >
                <i className="fa fa-sign-out" />
              </a>
            </span>
          </div>
    );
  }
}
