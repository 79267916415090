import React from "react";

import FullScreen from "./FullScreen";
import ToggleMenu from "./ToggleMenu";
import Logout from "./Logout";
import Settings from "./Settings";
import Usercreate from "./Usercreate";
import SpeechButton from "../../voice-control/components/SpeechButton";
import SearchMobile from "./SearchMobile";
import { Activities } from "../../activities";
import { LanguageSelector } from "../../i18n";
import RecentProjects from "./RecentProjects";
import axios from 'axios';
import {config} from '../../../config/config';
import Exportimport from "./exportimport";
const dbservConfig = {
  basePath: config.db_url
};
const userdata = JSON.parse(localStorage.getItem('user'));
//console.log("userdata",userdata)
const user  =  userdata == null ? "" : userdata;

/*
if(ow == undefined)
{
  var ows = 0
}
else
{
var ows = JSON.parse(localStorage.getItem('ow'));
}
var ow  =  ows == null ? null : ows
*/




/* if(userdata == null)
{
  localStorage.clear();
  window.location.href = 'http://localhost:3000/#/login';
}
else
{
  var user = JSON.parse(localStorage.getItem('user'));
  var ow = JSON.parse(localStorage.getItem('ow'));
}  */
//console.log("userdata details",userdata)
export default class Header extends React.Component {
  constructor() {
    super();
    this.state = {      
      n_id: user.id,
      n_userid : user.c_cid,
      n_utype : user.n_utype, 
      adsettings : 0     
};
  }

  componentDidMount(){

    if(this.state.n_id == 0)
      {
      this.setState({owname : "SUPERADMIN",adsettings : 1})
    }
    else
    {
console.log("ow value at header",JSON.parse(localStorage.getItem('ow')));
const ow = JSON.parse(localStorage.getItem('ow'));
    axios.get(dbservConfig.basePath+'/api/custom/selectall', { params: { t: 'sspl_usersow',cols:'*', whrc: "id = '"+ow+"'"} }) 
    .then( (user) => { 
      var owid = user.data[0].n_owid   
      var nid =  user.data[0].n_id     
      axios.get(dbservConfig.basePath+'/api/custom/selectall', { params: { t: 'sspl_userdetails',cols:'*', whrc: "id = '"+nid+"' "} }) 
    .then( (resp) => {
      var utype  = resp.data[0].n_utype
      console.log("utype",utype)
      if(utype == 1)
      {
         var tb = "view_sspl_useroutlet"
      }
      else
      {
        var tb = "view_sspl_userwarehouse"
      }
      axios.get(dbservConfig.basePath+'/api/custom/selectall', { params: { t: tb ,cols:'*', whrc: "n_id = '"+nid+"' and n_owid='"+owid+"'"} }) 
      .then( (finadata) => {
        this.setState({owname : finadata.data[0].c_cname })
      }).catch( (error) => { console.log(error); });
      
    }).catch( (error) => { console.log(error); });   
}).catch( (error) => { console.log(error); });
    }
    }

  render() {
    return (
      <header id="header" className="fixed-header">
        <div id="logo-group"  >
          <span id="logo">
            {<img
              src="assets/img/logo.png" // place your logo here
              alt="SmartAdmin" style = {{marginTop:"-10px"}}
            /> } 
          </span>          
          {/* Note: The activity badge color changes when clicked and resets the number to 0
         Suggestion: You may want to set a flag when this happens to tick off all checked messages / notifications */}
           
         {/* <Activities /> */}
       
        </div>
       
         <RecentProjects />
        <div className="pull-right" /*pulled right: nav area*/>
          <ToggleMenu
            className="btn-header pull-right" /* collapse menu button */
          />
          {this.state.adsettings == 1 ? <Usercreate className="btn-header transparent pull-right" /> : ""}
          {this.state.adsettings == 1 ? <Settings className="btn-header transparent pull-right" />: ""}
          {this.state.adsettings == 1 ? <Exportimport className="btn-header transparent pull-right" />: ""}
        
         {/* #MOBILE */}
          {/*  Top menu profile link : this shows only when top menu is active */}
          <ul
            id="mobile-profile-img"
            className="header-dropdown-list hidden-xs padding-5" >
            <li className="">
              <a href="#/"
                className="dropdown-toggle no-margin userdropdown"  data-toggle="dropdown" >
                <img
                  src="assets/img/avatars/sunny.png"
                  alt="Admin"
                  className="online"
                />
              </a>
              <ul className="dropdown-menu pull-right">
                <li>
                  <a href="#/" className="padding-10 padding-top-0 padding-bottom-0">
                    <i className="fa fa-cog" /> Setting
                  </a>
                </li>
                <li className="divider" />
                <li>
                  <a
                    href="#/views/profile"
                    className="padding-10 padding-top-0 padding-bottom-0"
                  >
                    <i className="fa fa-user" />
                    <u>P</u>rofile
                  </a>
                </li>
                <li className="divider" />
                <li>
                  <a href="#/"
                    className="padding-10 padding-top-0 padding-bottom-0"
                    data-action="toggleShortcut"
                  >
                    <i className="fa fa-arrow-down" /> <u>S</u>hortcut
                  </a>
                </li>
                <li className="divider" />
                <li>
                  <a href="#/"
                    className="padding-10 padding-top-0 padding-bottom-0"
                    data-action="launchFullscreen"
                  >
                    <i className="fa fa-arrows-alt" /> Full
                    <u>S</u>creen
                  </a>
                </li>
                <li className="divider" />
                <li>
                  <a
                    href="#/login"
                    className="padding-10 padding-top-5 padding-bottom-5"
                    data-action="userLogout"
                  >
                    <i className="fa fa-sign-out fa-lg" />
                    <strong>
                      <u>L</u>ogout
                    </strong>
                  </a>
                </li>
              </ul>
            </li>
          </ul>

          {/* logout button */}
          {/*<div id="logout" className="btn-header transparent pull-right">
            <span>
              <a
                href="#/login"
                title="Sign Out"
                data-logout-msg="You can improve your security further after logging out by closing this opened browser"
              >
                <i className="fa fa-sign-out" />
              </a>
            </span>
          </div>*/}

          <Logout />


          {/* search mobile button (this is hidden till mobile view port) */}
          {/* <SearchMobile className="btn-header transparent pull-right" />

          
          <form
            action="#/misc/search.html"
            className="header-search pull-right"
          >
            <input
              id="search-fld"
              type="text"
              name="param"
              placeholder="Find reports and more"
            />
            <button type="submit">
              <i className="fa fa-search" />
            </button>
            <a href="$" id="cancel-search-js" title="Cancel Search">
              <i className="fa fa-times" />
            </a>
          </form>
 */}
         

          <FullScreen className="btn-header transparent pull-right" />

          {/* multiple lang dropdown : find all flags in the flags page
          <LanguageSelector />  */}
         <span className="btn-header transparent pull-right" style= {{marginTop: "0px", marginLeft: "10px", color: "#fff",  background: "#6e3671", padding: "16px"}}>{this.state.owname}</span>
    

        </div>
        {/* end pulled right: nav area */}
      </header>
    );
  }
}
